.sms__inner {
    .form__control {
        display: flex;
        flex-direction: row;
        width: 30%;

    }
}

.sms__items {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    gap: 10px;
    input {
        width: 40%;
    }

}
.sms__item {

   display: flex;
   width: 100%;
gap: 30px;
.id {
    width: 100px;
}


justify-content: space-between;
text-align: left;
align-items: center;
box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
div {
    width: 100%;
}



}


.total__price-sms{
    color: red;
}


@media screen  and (max-width: 768px) {  
.sms__items {
    gap: 40px;
    input {
        width: 40%;
    }
}
    .sms__item {
        display: flex;
        flex-direction: column;
        height: auto;
        align-items: center;
        text-align: center;
        padding: 0.5rem;

    }

}
@media screen  and (max-width: 768px) {  
.sms__inner {

    .form__control{
        display: flex;
        flex-direction: column;
    }
}

}
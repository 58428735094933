.normal {
    background-color: rgb(148, 166, 209);;
    border: 1px solid rgb(82, 203, 224);
    padding: 0.6rem;
    // border-radius: 10px;
    cursor: pointer!important;
    transition: all 0.4s ease;
    color: var(--body-color);
    border-radius: 7px;
}

.normal:hover{
    background-color: rgb(30 64 175);
    border: 1px solid transparent;
}
.normal.active {
    background-color: red;
}
.home {
    margin-top: 100px;

.card__items {
    display: flex;
    gap: 20px;
    width: 50%;
    margin: 0 auto;
    font-size: 24px;
    font-weight: bold;
    justify-content: space-between;

}
    .card__item{
        width: 300px;
        height: 200px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        text-align: center;
        justify-content: center;
        background-color: rgba(23, 23, 23,0.2);
        border-radius: 10px;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    
    }
}

@media screen  and (max-width: 768px) {
    .card__items {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 50%;
        margin: 0 auto;
        font-size: 24px;
        font-weight: bold;
        .card__item{
            width: 200px;
            height: 100px;
            display: flex;
        }
    }

}
.add__user-form{
    display: flex;
    gap: 10px;
}




// MEDIA

@media screen  and (max-width: 1440px) { 

    .add__user-form{

        display: flex;
       flex-wrap: wrap;
    }
}
.user{
    position: relative;
    display: flex;
    gap: 30px;
    align-items: center;
    text-align: left;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
    padding: 0.4rem;
    border-radius: 2px;
    cursor: context-menu;
    
    div {
        width: 200px;
        gap: 20px;

        
    }

 
}

.user__functions{
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 20px;
    .created__at {
        position: absolute;
        bottom: 0;
        left: 0;
        font-size: 12px;
       
    }
}
.users__list {
    display: flex;
    gap: 30px;

    div {
        display: flex;
        width: 200px;
        text-align: left;
    }
}

.users__inner {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 80%;
    margin: 0 auto;
}


.add__user {
    display: flex;
}


@media screen  and (max-width: 768px) {
   
    .users{
     

        &__inner {
            width: 100%;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
        }
    }
.users__list {
    display: none;
    gap: 10px;
}

    .user {
        display: flex;
        flex-direction: column;
        gap: 5px;
    }
}
@media screen  and (max-width: 1200px) {
   
    .user__functions{
        position: relative;
        display: flex;
    flex-direction: column;
        gap: 20px;
        button {
            width: 30%;
            margin: 0 auto;
        }
        .created__at {
            position: absolute;
            bottom: 0;
            left: 0;
            font-size: 12px;
           
        }
    }

    .user{
        flex-wrap: wrap;
    }
}
.header{
 width: 100%;
//  background-color: var(--body-background);
//  color: var(--body-color);
    &__inner {
        display: flex;
        justify-content: space-between;
        padding: 0.4rem;
        // border-bottom: 1px solid black;
        align-items: center;
        text-align: center;
    }
    .user__avatar {
        position: relative;
        background-color: rgb(61, 155, 192);
        padding: 0.4rem;
        border-radius: 10px;
        cursor: pointer!important;
    }

    .user__options {
    display: flex;
    flex-direction: column;
    gap: 30px;
        position: absolute;
        z-index: 999999;
        background-color: darkcyan;
        width: 200px;
        height: 500px;
        right: 40px;
        top: 40px;
        padding: 0.4rem;
        border-radius: 10px;
    }
}
.close__user-options{
    position: absolute;
    bottom: 5px;
    color: red;
    font-weight: bold;
    right: 5px;
}
.header__menu{
 
    button {
        color: var(--body-color);
    }
    &-admin {
        display: flex;
        gap: 20px;
    }
  
}



@media screen  and (max-width: 768px) {
    header {
        height: 200px;
    }
   .header__menu-admin {
    font-size: 8px;
   }
   .logo {
    // font-size: 7px;
    a {
        padding: 5px;
    }
   }
    .header{
     

        &__inner {
            height: 200px;
display: flex;
flex-direction: column;
        }
    }
    .navigation {
        // display: none;
    }

    
    .user__options {
        display: flex;
        flex-direction: column;
        gap: 30px;
            position: absolute;
            background-color: darkcyan;
            width: 200px;
            height: 500px;
            right: 0px;
            top: 40px;
            padding: 0.4rem;
            border-radius: 10px;
        }

        .dark_mode {
            display: none;
        }
}

@media screen  and (max-width: 468px) {
.header__inner {
    font-size: 12px;
    .logo {
        a {
            font-size: 6px;
        }
    }
 
}

.navigation {
    margin-top: 20px;
  .header__menu {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
    // width: 50%;
}

}
@import "./styles/index.scss";

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
// background-color: var(--body-background);
// color:black;
}


.site{
  margin-top: 200px;
}
.admin__machines__inner{
    display: flex;
    width: 80%;
    margin: 0 auto;
    flex-direction: column;
    gap: 20px;
}

.admin__machine-item{
    display: flex;
    gap: 20px;
    align-items: center;
    text-align: left;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
    padding: 0.4rem;
    border-radius: 2px;
    cursor: context-menu;
    height: 70px;
    
.machine__id{
    width: 40px;
}
    div {
        width: 300px;
        display: flex;
        text-align: left;
        align-items: center;
        gap: 20px;

        button {
            width: 100%;
        }
    }
}

.machine__header-menu{
    display: flex;
    display: none;
    text-align: left;
    align-items: center;
    .machine__id{
        width: 40px;
    }
    div {
        width: 250px;
        display: flex;
        text-align: left;
        align-items: center;
    }
}
.admin__functions{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
}
@media screen  and (max-width: 768px) {  

    .admin__machine-item {
        display: flex;
        flex-direction: column;
        height: auto;
        flex-wrap: wrap;
    }
    .admin__functions{
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
}

@media screen  and (max-width: 1050px) {  

    .admin__machine-item {
        display: flex;
        flex-direction: column;
        height: auto;
        flex-wrap: wrap;
    }
}